<template>
  <div>
    <el-form class="query-form" inline>
      <el-form-item label="姓名">
        <el-input v-model="query.name" size="small"></el-input>
      </el-form-item>
      <el-form-item label="电话">
        <el-input v-model="query.mobile" size="small"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="small"
          @click="search"
          @keydown.enter="search"
          >查询</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button size="small" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="main-content">
      <el-table :data="tableData" style="width: 100%" border stripe>
        <el-table-column prop="name" label="姓名"> </el-table-column>
        <el-table-column prop="mobile" label="电话"> </el-table-column>
        <el-table-column prop="address" label="最近核酸检测日期">
          <template slot-scope="scope">
            {{ formatTime(scope.row.nucleicAcidTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="date" label="检测地点">
          <template slot-scope="scope">
            {{ scope.row.address }}
          </template>
        </el-table-column>
        <el-table-column prop="vaccinesType" label="最近疫苗接种日期">
          <template slot-scope="scope">
            {{ formatTime(scope.row.vaccinesTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="vaccinesType" label="疫苗接种类型">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="trigger('n', scope.row)"
              >核酸检测记录</el-button
            >
            <el-button type="text" @click="trigger('v', scope.row)"
              >疫苗接种记录</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total,sizes,prev, pager, next"
        style="text-align: right; margin-top: 20px"
        @size-change="sizeChange"
        @prev-click="prevClick"
        @next-click="nextClick"
        @current-change="pageCurrent"
        :page-size="query.ps"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- ---------------------------  记录  -------------------------------- -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
      <div style="max-height: 500px; overflow-y: scroll">
        <template v-if="currentType == 'n'">
          <el-timeline>
            <el-timeline-item
              v-for="(item, index) in nList"
              :key="index"
              :timestamp="formatTime(item.time)"
            >
              <p>检测地点：{{item.address}}</p>
              <p>检测结果：{{item.result?'阳性':'阴性'}}</p>
            </el-timeline-item>
          </el-timeline>
        </template>
        <template v-else>
          <el-timeline>
            <el-timeline-item
              v-for="(item, index) in vList"
              :key="index"
              :timestamp="formatTime(item.time) + ` 第${item.count||1}针`"
            >
              <p>接种类型：{{item.vaccinesType&&item.vaccinesType.name}}</p>
              <p>接种地点：{{item.address}}</p>
            </el-timeline-item>
          </el-timeline>
        </template>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  epidemicList,
  epidemicTypes,
  nucleicList,
  vaccinesList,
} from "@/api/api";
import page from "@/mixins/page";
import format from "@/mixins/format";
export default {
  data() {
    return {
      query: {
        pn: 1,
        ps: 10,
      },
      total: 0,
      tableData: [],
      type: [],
      dialogVisible: false,
      currentType:'',
      dialogTitle:'',
      nList:[],
      vList:[]
    };
  },
  mounted() {
    this.getTypes();
    this.getList();
  },
  mixins: [format, page],
  methods: {
    getList() {
      epidemicList(this.query).then((res) => {
        this.tableData = res.data.data.list;
        this.total = res.data.data.total;
      });
    },
    getTypes() {
      epidemicTypes().then((res) => {
        console.log(res);
        this.type = res.data.data;
      });
    },
    changeTime(e) {
      this.query.nucleicTimeA = e[0];
      this.query.nucleicTimeB = e[1];
    },
    // 弹窗
    trigger(type, obj) {
      this.dialogVisible = true
      this.currentType = type
      if (type == "n") {
        this.dialogTitle = '核酸检测记录'
        nucleicList({ mobile: obj.mobile }).then((res) => {
          this.nList = res.data.data;
        });
      } else {
        this.dialogTitle = '接种疫苗记录'
        vaccinesList({ mobile: obj.mobile }).then((res) => {
          this.vList = res.data.data;
        });
      }
    },
  },
};
</script>

<style scoped>
.query-form {
  margin-top: 20px;
  padding-top: 25px;
  background-color: #f2f2f2;
}
</style>